<template>
  <b-container fluid class="reports-savings-ratio-amount-data-content-wrapper px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'title_data_table_card')}} </h2>
          </template>

          <b-col cols="12">
            <p class="col-lg-10 pl-0">
              {{ $t( translationPath + 'table_description')}}
            </p>
          </b-col>
          <b-col cols="12">
            <b-table
              class="spirecta-simple-table pl-0 mb-0"
              stacked="md"
              :items="records"
              :fields="fieldsValues"
              :busy="busyState"
              :empty-text="$t(translationPath + 'table.empty_text')"
              show-empty
              hover
              responsive
              striped
            >
              <!--loader-->
              <template v-slot:table-busy>
                <loader/>
              </template>
              <!--loader ends here-->
            </b-table>
            <p class="col-lg-10 pl-0 pt-3">
              {{ $t( translationPath + 'table_description_definition')}}
            </p>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'

export default {
  name: 'SavingsRatioAmountDataContent',
  props: ['apiData'],
  components: { Loader },
  data () {
    return {
      translationPath: 'reports.performance.savings_ratio.',
      busyState: true
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldsValues () {
      return [
        { key: 'period', label: this.$t(this.translationPath + 'table.period') },
        { key: 'income_total', label: this.$t(this.translationPath + 'table.income_total'), class: 'text-left text-md-right', formatter: 'amountFomattor' },
        { key: 'expense_total', label: this.$t(this.translationPath + 'table.expense_total'), class: 'text-left text-md-right', formatter: 'amountFomattor' },
        { key: 'savings', label: this.$t(this.translationPath + 'table.savings'), class: 'text-left text-md-right', formatter: 'amountFomattor' },
        { key: 'accumulated_savings', label: this.$t(this.translationPath + 'table.accumulated_savings'), class: 'text-left text-md-right', formatter: 'amountFomattor' },
        { key: 'savings_ratio', label: this.$t(this.translationPath + 'table.savings_ratio'), class: 'text-left text-md-right', formatter: 'percentageFormator' },
        { key: 'accumulated_savings_ratio', label: this.$t(this.translationPath + 'table.accumulated_savings_ratio'), class: 'text-left text-md-right', formatter: 'percentageFormator' }
      ]
    },
    records () {
      return this.recalculatedTableData()
    }
  },
  methods: {
    percentageFormator (value) {
      return value.toString() + '%'
    },
    amountFomattor (value) {
      return this.currentCOA && this.currentCOA.locale && this.currentCOA.currency && this.currentCOA.currency_iso ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0) : value
    },
    recalculatedTableData () {
      let incomeTotalAcc = 0
      let expenseTotalAcc = 0
      const recordsData = this.apiData.map(item => {
        incomeTotalAcc += Math.round(item.income_total, 2)
        expenseTotalAcc += Math.round(item.expense_total, 2)
        const savings = parseFloat(item.income_total) - parseFloat(item.expense_total)
        const accumulatedSavings = incomeTotalAcc !== 0 ? (parseFloat(incomeTotalAcc) - parseFloat(expenseTotalAcc)) : 0
        const savingsRatio = (savings / parseFloat(item.income_total)) * 100
        const accumulatedSavingsRatio = incomeTotalAcc !== 0 ? ((accumulatedSavings / parseFloat(incomeTotalAcc)) * 100) : 0
        return Object.assign(item, {
          savings: Math.round(savings, 2),
          accumulated_savings: Math.round(accumulatedSavings, 2),
          savings_ratio: isFinite(savingsRatio) ? Math.round(savingsRatio, 2) : 0,
          accumulated_savings_ratio: Math.round(accumulatedSavingsRatio, 2)
        })
      }).sort(function (a, b) {
        const nameA = a.period
        const nameB = b.period
        return nameB.localeCompare(nameA)
      })
      setTimeout(function () {
        this.busyState = false
      }.bind(this), 1000)
      return recordsData
    }
  },
  filters: {
    formatAmount
  }
}
</script>

<style lang="scss">
  .reports-savings-ratio-amount-data-content-wrapper {
    .card-body {
      padding-top: 0px;
    }
    .table-content-main {
      top: -15px;
      position: relative;
      margin-left: 35px !important;
      margin-right: 35px !important;
      border-radius: 0;
      border: none;
      background: white;
    }
    h2 {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
</style>
