<template>
  <div class="performance-report-savings-ratio">

    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>
      <b-container class="main-gradient-header-wrapper">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="7" md="8" lg="9" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
            </b-col>
            <b-col cols="12" sm="5" md="4" lg="3" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                default-period-type="last-year"
                :show-predefined-periods="true"
                :show-resolution="true"
                :allowed-resolutions="['month', 'year']"
                @period-selected="applyFilter"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-4" no-gutters>
          <b-col no-gutters>
            <savings-ratio-submenu ref="Tabmenu" :translationPath="this.translationPath" @tab-switched="onTabSwitched" :active="this.activeTab" :currencySymbol="currencySymbol"></savings-ratio-submenu>
          </b-col>
        </b-row>

      </b-container>

      <b-row class="main-gradient-chart-wrapper" :class="{'custom-pb-6': activeTab === 'income-expense', 'pb-4': activeTab === 'settings'}">
        <div class="pb-5" style="width:100%;height:500px;" v-if="activeTab !== 'settings'">

          <savings-ratio-percentage-chart :apiData="apiData" v-if="this.activeTab === 'percentage'" :busy="busyState.chart"></savings-ratio-percentage-chart>
          <savings-ratio-percentage-acc-chart :apiData="apiData" v-if="this.activeTab === 'accumulated-percentage'" :busy="busyState.chart"></savings-ratio-percentage-acc-chart>

          <savings-ratio-amount-chart :apiData="apiData" v-if="this.activeTab === 'amount'" :busy="busyState.chart" :currencySymbol="currencySymbol"></savings-ratio-amount-chart>
          <savings-ratio-amount-acc-chart :apiData="apiData" v-if="this.activeTab === 'accumulated-amount'" :busy="busyState.chart" :currencySymbol="currencySymbol"></savings-ratio-amount-acc-chart>

          <savings-ratio-income-expenses-chart :apiData="apiData" v-if="this.activeTab === 'income-expense'" :busy="busyState.chart" :currencySymbol="currencySymbol"></savings-ratio-income-expenses-chart>
        </div>
      </b-row>

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0" :class="{ 'pt-0': activeTab === 'settings'}">
      <savings-ratio-setting v-if="activeTab === 'settings'" :sections="sections" @sections-update="handleSectionUpdate" @settings-updated="handleSettingsUpdate" ></savings-ratio-setting>
      <savings-ratio-data-table v-if="activeTab !== 'settings'" :apiData="apiData" :busy="busyState.table"></savings-ratio-data-table>
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import SavingsRatioPercentageChart from './SavingsRatioPercentageChart'
import SavingsRatioAmountChart from './SavingsRatioAmountChart'
import SavingsRatioSubmenu from './SavingsRatioSubmenu'
import SavingsRatioDataTable from './SavingsRatioDataTable'
import SavingsRatioIncomeExpensesChart from './SavingsRatioIncomeExpensesChart'
import SavingsRatioPercentageAccChart from './SavingsRatioPercentageAccChart'
import SavingsRatioAmountAccChart from './SavingsRatioAmountAccChart'
import SavingsRatioSetting from './SavingsRatioSetting'
import PeriodSelector from '@/components/common/PeriodSelector'
import _ from 'lodash'

export default {
  name: 'SavingsRatio',
  mixins: [titleMixins],
  components: {
    SavingsRatioPercentageChart,
    SavingsRatioPercentageAccChart,
    SavingsRatioAmountChart,
    SavingsRatioAmountAccChart,
    SavingsRatioDataTable,
    SavingsRatioSubmenu,
    SavingsRatioIncomeExpensesChart,
    SavingsRatioSetting,
    PeriodSelector
  },
  data () {
    return {
      start_date: new Date(new Date().setMonth(new Date().getMonth() - 12)).toISOString().slice(0, 8).toString() + '01', // First day of this month a year ago
      end_date: new Date().toISOString().slice(0, 10), // Todays date
      resolution: 'month',
      apiData: [],
      activeTab: 'percentage',
      dateRange: {},
      busyState: {
        chart: true,
        table: true
      },
      translationPath: 'reports.performance.savings_ratio.',
      sections: [
        {
          label: 'common.incomes',
          description: 'common.settings.descriptions.income',
          model: 'income',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.expenses',
          description: 'common.settings.descriptions.expense',
          model: 'expense',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.title',
          model: 'title',
          value: null,
          required: true,
          disableButtons: true,
          child: [
            {
              type: 'input'
            }
          ]
        },
        {
          label: 'common.advanced_filter.description',
          model: 'description',
          value: null,
          show: true,
          disableButtons: true,
          child: [
            {
              type: 'textarea'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
    this.$bvToast.toast(this.$t('common.toasts.loading_data'), {
      title: this.$t('reports.performance.savings_ratio.title'),
      variant: 'info',
      solid: true
    })
    this.fetchApiData()
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.performance_reports'), to: '/reports/performance' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t('reports.performance.savings_ratio.title')
    },
    currencySymbol () {
      return this.currentCOA && Object.prototype.hasOwnProperty.call(this.currentCOA, 'currency') && this.currentCOA.currency ? this.currentCOA.currency : 'kr'
    }
  },
  async created () {
    if (this.apiData !== null) {

    }
  },
  methods: {
    handleSectionUpdate (value) {
      this.sections = value
    },
    handleSettingsUpdate (value) {
      this.handleSectionUpdate(value)
      this.fetchApiData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description'), {
            title: this.$t('common.filter_settings_title'),
            variant: 'success',
            solid: true
          })
        })
    },
    applyFilter (eventData) {
      this.resolution = eventData.resolution
      this.start_date = eventData.sDateStart
      this.end_date = eventData.sDateEnd
      this.fetchApiData()
    },
    fetchApiData () {
      this.busyState.chart = true
      this.busyState.table = true
      const sectionsValue = _.flatten([...this.sections].filter((el) => el.model !== 'title' && el.model !== 'description').map((el) => el.value).filter((el) => el))
      const include = sectionsValue.length > 0 ? sectionsValue.join(',') : null
      // http://api.spirecta.test/api/v1/reports/performanceRatios?start_date=2010-01-01&resolution=week
      let ApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/performanceRatios?start_date=${this.start_date}&end_date=${this.end_date}&resolution=${this.resolution}`
      if (include) {
        ApiEndpoint += `&include=${include}`
      }
      return axios.get(ApiEndpoint)
        .then(response => response.data.data)
        .then(responseData => {
          this.apiData = Object.prototype.hasOwnProperty.call(responseData, 'ratios') ? responseData.ratios : []
          this.busyState.chart = false
          this.busyState.table = false
          return true
        })
        .catch(err => {
          console.error(err)
          return false
        })
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: newtab } })
      }
    },
    changeTab (tab) {
      if (['percentage', 'accumulated-percentage', 'amount', 'accumulated-amount', 'income-expense', 'settings'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
        this.setPageTitle(this.titleMeta)
      }
    }
  },
  watch: {
    start_date: function (newVal, oldVal) {
      if (this.showToastOnYearChange) {
        this.$bvToast.toast(this.$t('common.toasts.loading_data_for_period', { start_date: this.start_date, end_date: this.end_date }), {
          title: this.$t('reports.performance.savings_ratio.title'),
          variant: 'info',
          solid: true
        })
      } else {
        this.showToastOnYearChange = true
      }

      if (newVal) {
        this.fetchApiData().then(status => {
          this.$bvToast.toast(this.$t('common.toasts.data_for_period_is_now_loaded', { start_date: this.start_date, end_date: this.end_date }), {
            title: this.$t('reports.performance._common.savings_ratio_report'),
            variant: 'success',
            solid: true
          })
        })
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    }
  }
}
</script>

<style lang="scss">
  .performance-report-savings-ratio
  {
    width: 100%;
  }
  .custom-pb-6 {
    padding-bottom: 3.3rem !important;
  }
</style>
