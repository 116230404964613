<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="percentage" :class="{selected: activeTab === 'percentage'}"  @click="onTabClick">
          {{ $t(translationPath + 'percentageTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="accumulated-percentage" :class="{selected: activeTab === 'accumulated-percentage'}"  @click="onTabClick">
          {{ $t( translationPath + 'accumulatedPercentageTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="amount" :class="{selected: activeTab === 'amount'}"  @click="onTabClick">
          {{ $t( translationPath + 'amountTab', {currency: currencySymbol}) }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="accumulated-amount" :class="{selected: activeTab === 'accumulated-amount'}"  @click="onTabClick">
          {{ $t( translationPath + 'accumulatedAmountTab', {currency: currencySymbol}) }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="income-expense" :class="{selected: activeTab === 'income-expense'}"  @click="onTabClick">
          {{ $t( translationPath + 'incomeExpenseTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="settings" :class="{selected: activeTab === 'settings'}"  @click="onTabClick">
          {{ $t('common.header.header_menu.user_menu.settings') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    translationPath: {},
    active: {
      type: String,
      default: 'percentage'
    },
    currencySymbol: {
      type: String,
      default: 'kr'
    }
  },
  name: 'SavingsSubmenu',
  data () {
    return {
      activeTab: null
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
.submenu-container {
  &.dark {
    .col-md-auto {
      padding-right: 0;
    }
  }
}
</style>
