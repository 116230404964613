<template>
  <b-container fluid class="reports-performance-savings-ratio-income-expenses-chart">
    <d3-spirecta-difference-chart
      :graphData="chartData"
      :graphOptions="chartOptions"
    />
  </b-container>
</template>

<script>
import D3SpirectaDifferenceChart from '@/components/d3charts/d3-spirecta-difference-chart/index'

export default {
  name: 'SavingsRatioIncomeExpensesChart',
  props: ['apiData', 'currencySymbol'],
  components: {
    D3SpirectaDifferenceChart
  },
  data () {
    return {
      translationPath: 'reports.performance.savings_ratio.',
      chartData: [],
      chartOptions: {
        primaryColor: '#b5c7e4',
        primaryLabel: this.$t('common.incomes'),
        secondaryColor: '#9a9bae',
        secondaryLabel: this.$t('common.expenses'),
        DifferenceNegativeColor: '#dda3a2',
        DifferencePositiveColor: '#8cccad',
        LabelPositive: this.$t('common.difference'),
        LabelNegative: this.$t('common.difference'),
        xAxisType: 'double_row', // or double_row,
        xLabelFormat: '%Y-%m',
        xLabelTopFormat: '%b',
        xLabelBottomFormat: '%Y',
        sliderLabelFormat: '%b %Y',
        amountSuffix: ' ' + this.currencySymbol
      }
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      // this.chartOptions.amountSuffix = ' ' + this.currencySymbol
      this.chartData = []

      for (const item in this.apiData) {
        this.chartData.push({
          date: this.apiData[item].period,
          primary: this.apiData[item].income_total,
          secondary: this.apiData[item].expense_total
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .reports-performance-savings-ratio-income-expenses-chart{
    padding-top: 0px;
    .chart-wrapper svg {
      /* -webkit-box-shadow: -13px 20px 39px 0 rgba(82, 86, 112, 0.55); */
      -webkit-box-shadow: none;
      /* box-shadow: -13px 20px 39px 0 rgba(82, 86, 112, 0.55); */
      box-shadow: none;
    }
  }
</style>
