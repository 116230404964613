<template>
  <b-container fluid class="reports-performance-savings-ratio-percentage-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'savings-ratio-percentage-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>

  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'

export default {
  name: 'SavingsRatioPercentageChart',
  props: ['apiData'],
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.performance.savings_ratio.',
      chartData: null
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      const savingsRatioLabel = this.$t(this.translationPath + 'savings_ratio')
      const suffix = ' %'
      this.chartOptions.legend.display = false

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + suffix
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return savingsRatioLabel + ': ' + tooltipItem.yLabel.toFixed(2) + suffix
      }

      const labelData = []
      const ratioData = []

      for (var index in this.apiData) {
        ratioData.push(Math.round(this.apiData[index].savings_ratio))
        labelData.push(this.apiData[index].period)
      }

      const colors = ratioData.map((value) => value < 0 ? '#dda3a2' : '#8cccad')
      const datasetSavingsRatio = {
        label: this.$t(this.translationPath + 'chart_label_savings_ratio'),
        data: ratioData,
        borderColor: colors,
        backgroundColor: colors
      }

      this.chartData = {
        labels: labelData,
        datasets: [datasetSavingsRatio]
      }
    }
  }
}
</script>

<style lang="scss">
  .reports-performance-savings-ratio-percentage-chart{

  }
</style>
