<template>
  <b-container fluid class="reports-performance-savings-ratio-accumulated-savings-chart">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'savings-ratio-accumulated-savings-chart'+this.activeTab"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChartWithGradient'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'SavingsRatioPercentageAccChart',
  props: ['apiData', 'activeTab', 'currencySymbol'],
  mixins: [chartOptions],
  components: {
    LineChart
  },
  data () {
    return {
      translationPath: 'reports.performance.savings_ratio.',
      chartData: null
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    },
    activeTab () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      // Custom chart options
      this.chartOptions.legend.display = false
      this.chartOptions.scales.xAxes[0].gridLines.display = false
      const savingsRatioLabel = this.$t(this.translationPath + 'savings_ratio')
      const suffix = ' %'

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return savingsRatioLabel + ': ' + formatNumberToFull(tooltipItem.yLabel.toFixed(2)) + suffix
      }

      const labelData = []
      const accumulatedData = []
      let tmpAccumulatedSavingsRatio = 0
      let tmpIncomeTotalAcc = 0
      let tmpExpensesTotalAcc = 0

      for (var index in this.apiData) {
        labelData.push(this.apiData[index].period)

        tmpIncomeTotalAcc += parseInt(this.apiData[index].income_total)
        tmpExpensesTotalAcc += parseInt(this.apiData[index].expense_total)

        if (tmpIncomeTotalAcc !== 0) {
          tmpAccumulatedSavingsRatio = ((tmpIncomeTotalAcc - tmpExpensesTotalAcc) / tmpExpensesTotalAcc * 100).toFixed(0)
        }

        accumulatedData.push(tmpAccumulatedSavingsRatio)
      }

      const datasetAccumulatedSavings = {
        label: this.$t(this.translationPath + 'chart_label_savings_ratio_percentage'),
        data: accumulatedData,
        type: 'line',
        backgroundColor: '#8cccad'
      }

      this.chartData = {
        labels: labelData,
        datasets: [datasetAccumulatedSavings]
      }
    }
  }
}
</script>

<style lang="scss">
  .reports-performance-savings-ratio-accumulated-savings-chart{

  }
</style>
