<template>
  <b-container fluid class="reports-performance-savings-ratio-amount-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'savings-ratio-percentage-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'SavingsRatioAmountChart',
  props: ['apiData', 'currencySymbol'],
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.performance.savings_ratio.',
      chartData: null
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  methods: {
    fillChart () {
      // Custom chart options
      this.chartOptions.legend.display = false
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'
      const savingsRatioLabel = this.$t(this.translationPath + 'savings_amount', { currency: currencySymbol }) // TODO: fix currency
      const suffix = ' ' + currencySymbol // TODO: fix currency setting

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        const labelValue = Math.floor(Number(tooltipItem.yLabel))
        return savingsRatioLabel + ': ' + formatNumberToFull(labelValue) + suffix
      }

      const labelData = []
      const ratioData = []
      const incomeData = []
      const expenseData = []
      const savingsData = []

      for (var index in this.apiData) {
        ratioData.push(this.apiData[index].savings_ratio)
        labelData.push(this.apiData[index].period)
        incomeData.push(this.apiData[index].income_total)
        expenseData.push(this.apiData[index].expense_total)
        savingsData.push((this.apiData[index].income_total - this.apiData[index].expense_total))
      }

      const colors = savingsData.map((value) => value < 0 ? '#dda3a2' : '#8cccad')
      const datasetSavings = {
        label: this.$t(this.translationPath + 'chart_label_savings'),
        data: savingsData,
        type: 'bar',
        borderColor: colors,
        backgroundColor: colors
      }

      this.chartData = {
        labels: labelData,
        datasets: [datasetSavings]
      }
    }
  }
}
</script>

<style lang="scss">
  .reports-performance-savings-ratio-amount-chart{

  }
</style>
